<template>
  <div class="posters">
    <div class="filter__form-change-wrap">
      <form
        @submit.prevent="submitFilterForm"
        ref="filterForm"
        class="filter__form filter__form-change"
        autocomplete="off"
      >
        <div class="search filter search__long">
          <div class="search__input">
            <label for="posters_search" class="search__label">Название мероприятия для поиска</label>
            <div class="search__input__wrap">
              <input v-model="form.title.value" id="posters_search" type="text" maxlength="255" />
              <button v-if="form.title.value" type="button" @click="resetSearchInput">
                <CloseIcon />
              </button>
            </div>
          </div>
          <div class="filter__item filter__date">
            <label>Диапазон дат</label>
            <div class="filter__date__inputs">
              <div class="filter__date__input">
                <flat-pickr
                  v-model="form.start_date.value"
                  :config="flatPickrConfig"
                  placeholder="дд.мм.гггг"
                >
                </flat-pickr>
                <DatePickIcon />
              </div>
              <div class="filter__date__input">
                <flat-pickr v-model="form.end_date.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
                </flat-pickr>
                <DatePickIcon />
              </div>
            </div>
          </div>
          <div class="search__btn search__btn--with-clear">
            <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
            <button type="submit" class="btn-blue_dark" :disabled="loading">
              <LoadingIndicator v-if="loading" title="Загрузка" />
              <template v-else>Найти</template>
            </button>
          </div>
        </div>
      </form>

      <a
        @click.prevent="changeFilterForm"
        class="filter__change-btn"
        :class="{ hide__btn: isFilterVisible }"
        href="#"
      >
        <span v-if="isFilterVisible">Скрыть фильтр</span>
        <span v-else>Поиск и фильтр по афишам</span>
        <ArrowDownIcon />
      </a>
    </div>

    <div class="poster__tabs posters__top_padding">
      <ul class="poster__tabs__list posters-page__tabs__list">
        <li>
          <div class="poster__tabs__list-item" v-if="allPosters.data && allPosters.data.length">
            <a @click.prevent="setActive(0)" :class="{ active: activeTab === 0 }"> Все мероприятия </a>
          </div>
        </li>
        <li :key="index" v-for="(item, index) in posters">
          <div class="poster__tabs__list-item" v-if="item.data && item.data.length">
            <a
              @click.prevent="setActive(item.data[0].category.id)"
              :class="{ active: activeTab === item.data[0].category.id }"
            >
              {{ item.data[0].category.title }}
            </a>
          </div>
        </li>
      </ul>
      <div v-if="allPosters.data && allPosters.data.length">
        <div v-show="activeTab === 0" class="poster__tabs__content posters-page__tabs__content">
          <div
            v-if="
              currentCategory === 0 && $store.state.filterResponse && !$store.state.filterResponse.data.length
            "
          >
            <p v-if="$store.state.filterData.get('title')" class="poster__filter_not_found">
              По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
            </p>
            <p v-else class="poster__filter_not_found">По вашему запросу ничего не найдено</p>
          </div>
          <template v-else>
            <div class="poster__tabs__content__item" :key="k" v-for="(poster, k) in allPosters.data">
              <div class="poster__tabs__content__img">
                <div class="img__content" v-if="poster.head_img">
                  <div class="img__upper__layer"></div>
                  <img :src="poster.head_img | image($store.state.api)" :alt="poster.head_img | image_alt" />
                </div>
              </div>
              <div class="poster__tabs__content__wrap" :class="{ no_image: !poster.head_img }">
                <router-link
                  :to="{
                    name: 'poster_item',
                    params: { id: poster.id, url: poster.link },
                  }"
                  v-if="poster.title"
                  class="poster__tabs__content__title"
                >
                  {{ poster.title }}
                </router-link>
                <div class="poster__tabs__content__info">
                  <a href="#" v-if="poster.category && poster.category.title">
                    <div class="poster__tabs__content__category">
                      {{ poster.category.title }}
                    </div>
                  </a>
                  <div class="poster__tabs__content__date" v-if="poster.date_publication">
                    <time :datetime="poster.date_publication | robotDate">
                      {{ poster.date_publication | humanDatePosterRu(poster.date_end) }}
                    </time>
                  </div>
                </div>
              </div>
            </div>
            <a v-if="loading" href="#" class="show-more-link posters__top_padding">
              <LoadingIndicator title="Загрузка" />
            </a>
            <a
              @click.prevent="filteredLoadMore"
              v-else-if="
                currentCategory === 0 &&
                $store.state.filterResponse &&
                $store.state.filterResponse.next_page_url
              "
              href="#"
              class="show-more-link posters__top_padding"
            >
              Показать ещё
            </a>
            <a
              @click.prevent="loadMore"
              v-else-if="allPosters.next_page_url"
              href="#"
              class="show-more-link posters__top_padding"
            >
              Показать ещё
            </a>
          </template>
        </div>
      </div>
      <div :key="index" v-for="(item, index) in posters">
        <div v-if="item.data && item.data.length">
          <div
            v-show="activeTab === item.data[0].category.id"
            class="poster__tabs__content posters-page__tabs__content"
          >
            <div
              v-if="
                currentCategory === item.data[0].category.id &&
                $store.state.filterResponse &&
                !$store.state.filterResponse.data.length
              "
            >
              <p v-if="$store.state.filterData.get('title')" class="poster__filter_not_found">
                По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
              </p>
              <p v-else class="poster__filter_not_found">По вашему запросу ничего не найдено</p>
            </div>
            <template v-else>
              <div class="poster__tabs__content__item" :key="k" v-for="(poster, k) in item.data">
                <div class="poster__tabs__content__img">
                  <div class="img__content" v-if="poster.head_img">
                    <div class="img__upper__layer"></div>
                    <img :src="poster.head_img | image($store.state.api)" :alt="poster.head_img | image_alt" />
                  </div>
                </div>
                <div class="poster__tabs__content__wrap" :class="{ no_image: !poster.head_img }">
                  <router-link
                    :to="{
                      name: 'poster_item',
                      params: { id: poster.id, url: poster.link },
                    }"
                    v-if="poster.title"
                    class="poster__tabs__content__title"
                  >
                    {{ poster.title }}
                  </router-link>
                  <div class="poster__tabs__content__info">
                    <a href="#" v-if="poster.category && poster.category.title">
                      <div class="poster__tabs__content__category">
                        {{ poster.category.title }}
                      </div>
                    </a>
                    <div class="poster__tabs__content__date" v-if="poster.date_publication">
                      <time :datetime="poster.date_publication | robotDate">
                        {{ poster.date_publication | humanDatePosterRu(poster.date_end) }}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
              <a v-if="loading" href="#" class="show-more-link posters__top_padding">
                <LoadingIndicator title="Загрузка" />
              </a>
              <a
                @click.prevent="filteredLoadMore"
                v-else-if="
                  currentCategory === item.data[0].category.id &&
                  $store.state.filterResponse &&
                  $store.state.filterResponse.next_page_url
                "
                href="#"
                class="show-more-link posters__top_padding"
              >
                Показать ещё
              </a>
              <a
                @click.prevent="loadMore"
                v-else-if="item.next_page_url"
                href="#"
                class="show-more-link posters__top_padding"
              >
                Показать ещё
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import DatePickIcon from "@/components/svg/DatePickIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";
import axios from "axios";
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "PostersFilter",
  components: {
    FlatPickr,
    DatePickIcon,
    LoadingIndicator,
    CloseIcon,
    ArrowDownIcon,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  mounted() {},
  data() {
    return {
      isFilterVisible: false,
      activeTab: 0,
      currentCategory: 0,
      tabsOptions: {
        slidesPerView: 2,
        loop: false,
        clickable: true,
        speed: 800,
        spaceBetween: 16,
        breakpoints: {
          320: {
            slidesPerView: 2.5,
          },
          360: {
            slidesPerView: 3,
          },
          420: {
            slidesPerView: 3.5,
          },
          480: {
            slidesPerView: 4,
          },
          620: {
            slidesPerView: 5,
          },
        },
      },
      flatPickrConfig: {
        locale: Russian,
        enableTime: true,
        defaultHour: 0,
        defaultMinute: 0,
        dateFormat: "d.m.Y H:i",
        disableMobile: true,
      },
      loading: false,
      form: {
        title: {
          value: null,
          message: null,
        },
        start_date: {
          value: null,
          message: null,
        },
        end_date: {
          value: null,
          message: null,
        },
        category_id: {
          value: null,
          message: null,
        },
      },
    };
  },
  computed: {
    /**
     * Постеры для табов
     */
    posters() {
      if (
        this.$store.state.filterResponse &&
        this.$store.state.filterResponse.data.length &&
        this.currentCategory > 0
      ) {
        let filteredPosters = [];
        filteredPosters = filteredPosters.concat(this.$store.state.posters_page?.posters);
        filteredPosters.forEach((poster, index) => {
          if (poster.data[0]?.category_id === this.currentCategory) {
            filteredPosters[index] = this.$store.state.filterResponse;
          }
        });
        return filteredPosters;
      }
      return this.$store.state.posters_page?.posters
        ? this.$store.state.posters_page?.posters.filter((item) => {
            return item.data && item.data.length;
          })
        : [];
    },
    /**
     * Все постеры
     */
    allPosters() {
      if (
        this.$store.state.filterResponse &&
        this.$store.state.filterResponse.data.length &&
        this.currentCategory === 0
      ) {
        return this.$store.state.filterResponse;
      }
      return this.$store.state.posters_page?.all_posters;
    },
    /**
     * Все категории
     */
    categories() {
      return this.$store.state.posters_page?.categories;
    },
  },
  methods: {
    changeFilterForm() {
      let filter = this.$refs.filterForm;
      filter.style.display = this.isFilterVisible ? "none" : "flex";
      this.isFilterVisible = !this.isFilterVisible;
    },
    setActive(tab) {
      this.activeTab = tab;
    },
    /**
     * Пагинация
     * @returns {Promise<void>}
     */
    async loadMore() {
      if (!this.loading) {
        this.loading = true;
        if (this.activeTab === 0) {
          await axios
            .get(this.allPosters.next_page_url)
            .then(({ data }) => {
              this.loading = false;
              /**
             все афиши
             **/
              this.allPosters.data = this.allPosters.data.concat(data.all_posters.data);
              this.allPosters.next_page_url = data.all_posters.next_page_url;
            })
            .catch(({ response }) => {
              this.loading = false;
              console.error(response?.data?.message);
            });
        } else if (this.activeTab > 0) {
          const needCategory = this.activeTab;
          let nextPageUrl = "";
          let categoryIndex;
          this.posters.forEach((item, index) => {
            if (item.data[0].category.id === needCategory) {
              nextPageUrl = item.next_page_url;
              categoryIndex = index;
            }
          });
          await axios
            .get(nextPageUrl)
            .then(({ data }) => {
              this.loading = false;
              /**
             афиши по табам
             */
              this.posters[categoryIndex].data = this.posters[categoryIndex].data.concat(
                data.posters[categoryIndex].data
              );
              this.posters[categoryIndex].next_page_url = data.posters[categoryIndex].next_page_url;
            })
            .catch(({ response }) => {
              this.loading = false;
              console.error(response?.data?.message);
            });
        }
      }
    },

    async filteredLoadMore() {
      if (!this.loading) {
        this.loading = true;
        await this.$store
          .dispatch("POST_POSTERS_FILTER", {
            data: this.$store.state.filterData,
            url: this.$store.state.filterResponse.next_page_url,
          })
          .then((response) => {
            this.loading = false;
            const oldResponse = this.$store.state.filterResponse;
            this.$store.state.filterResponse = response.data.posters;
            this.$store.state.filterResponse.data = oldResponse.data.concat(
              this.$store.state.filterResponse.data
            );
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Фильтр афиш
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        this.currentCategory = this.activeTab;
        const fd = new FormData();
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        Object.keys(this.form).forEach((key) => {
          if (key === "category_id") {
            if (this.currentCategory > 0) {
              fd.append(key, this.currentCategory);
            }
          } else {
            if (this.form[key].value !== null) fd.append(key, this.form[key].value);
          }
        });
        this.$store.state.filterData = fd;
        await this.$store
          .dispatch("POST_POSTERS_FILTER", { data: fd })
          .then((response) => {
            this.loading = false;
            /**
             * полученин отфильтрованных данных
             */
            this.$store.state.filterResponse = response.data.posters;
            this.filteredTab = this.currentCategory;
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },

    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.state.filterData = null;
      this.$store.state.filterResponse = null;
      this.$store.state.filterList = [];
      this.filteredTab = null;
    },

    resetSearchInput() {
      this.form.title.value = null;
    },
  },
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'
</style>
