<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section section-pb48">
          <PostersSlider />
          <PostersFilter />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import PostersSlider from "@/views/mycity/poster/components/PostersSlider.vue";
import PostersFilter from "@/views/mycity/poster/components/PostersFilter.vue";

export default {
  name: "PostersComponent",
  async asyncData({ store }) {
    await store.dispatch("GET_POSTERS_PAGE");
  },
  components: {
    PostersSlider,
    PostersFilter,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.filterData = null;
    this.$store.state.filterResponse = null;
    this.$store.state.filterList = [];
    next();
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>
<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/home/index.styl"
@import "~@/styles/parts/mycity/poster/posters.styl"
@import "~@/styles/parts/swiper.styl"
</style>
